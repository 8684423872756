import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Gender, User } from "../../types/User";
import { addUser } from "../../api/usersAPI";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { set, toLower } from "lodash/fp";
import isString from "../../utils/isString";
import { styled } from "@mui/material/styles";
import genderLabel from "../../utils/genderLabel";
export const StyledTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    color: #d32f2f;
  }
  margin-bottom: 20px;
`;
const defaultUser = {
  firstname: "",
  dateOfBirth: new Date(),
  jmbg: "",
  lastname: "",
  email: "",
  gender: Gender.MALE,
  deleted: false,
};
const AddUserButton = () => {
  const [isOpen, setOpen] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<User>({
    defaultValues: defaultUser,
  });

  const onAddUser = async (data: User) => {
    await addUser(objectToLowerCase(data) as User);
    setOpen(false);
    reset(defaultUser);
  };
  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        color="success"
        startIcon={<AddIcon />}
      >
        Dodaj korisnika
      </Button>

      <Drawer open={isOpen} onClose={() => setOpen(false)} anchor="right">
        <Box
          style={{ height: "100%" }}
          m={10}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <form
            id="user-form"
            onSubmit={handleSubmit(onAddUser)}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Controller
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              name="firstname"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  label="Ime"
                  error={!!errors.firstname}
                  helperText={errors.firstname?.message}
                />
              )}
            />
            <Controller
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              name="lastname"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  label="Prezime"
                  error={!!errors.lastname}
                  helperText={errors.lastname?.message}
                />
              )}
            />
            <Controller
              name="jmbg"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  label="JMBG"
                  error={!!errors.jmbg}
                  helperText={errors.jmbg?.message}
                />
              )}
            />
            <Controller
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              name="email"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  label="Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  label="Adresa"
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              )}
            />
            <Controller
              name="telephoneNumber"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  label="Telefon"
                  error={!!errors.telephoneNumber}
                  helperText={errors.telephoneNumber?.message}
                />
              )}
            />
            <FormControl fullWidth>
              <InputLabel id="input-result-label">Pol</InputLabel>
              <Controller
                name="gender"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Ovo polje je obavezno.",
                  },
                }}
                render={({ field }) => (
                  <Select {...field} label="Pol">
                    {Object.keys(Gender).map((res) => (
                      <MenuItem value={res} key={res}>
                        {genderLabel(res as Gender)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Datum rodjenja"
                  format="dd.MM.yyyy"
                />
              )}
            />
          </form>
          <Button
            type="submit"
            color="success"
            variant="contained"
            form="user-form"
          >
            Dodaj korisnika
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

const objectToLowerCase = (val: any) => {
  return Object.entries(val).reduce((previousValue, currentValue) => {
    const [key, value] = currentValue;
    if (isString(value)) {
      return set(key, toLower(value))(previousValue);
    }
    return set(key, value)(previousValue);
  }, {});
};

export default AddUserButton;
