import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import useProtocols from "../../api/hooks/useProtocols";
import ProtocolEntity from "../../types/Protocol";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { format } from "date-fns";
import UserAnalysis from "./UserAnalysis";
import AddAnalysisButton from "../analysis/AddAnalysisButton";
import useAnalysis from "../../api/hooks/useAnalysis";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface Props {
  userId: string;
}

const UserProtocols = ({ userId }: Props) => {
  const protocols = useProtocols(userId);

  return (
    <Fragment>
      {protocols?.map((protocol, i) => (
        <SingleProtocolView
          protocol={protocol}
          userId={userId}
          expanded={i === 0}
        />
      ))}
    </Fragment>
  );
};

const SingleProtocolView = ({
  protocol,
  userId,
  expanded,
}: {
  protocol: ProtocolEntity;
  userId: string;
  expanded?: boolean;
}) => {
  const analysis = useAnalysis(userId, protocol.id);
  const navigate = useNavigate();
  const canBePrinted = analysis?.every((i) => i.doneResult);
  return (
    <Accordion defaultExpanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography component="span">
          Broj protokola: {protocol.protocolId} -{" "}
          {format(protocol.createdAt, "dd.MM.yyyy HH:mm:ss")}
          {protocol.pregnant && " - TRUDNICA"}
          {protocol.diagnosis && ` - Dijagnoza: ${protocol.diagnosis}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row-reverse" spacing={2} sx={{ mb: 2 }}>
          {canBePrinted && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                navigate(`/user/${userId}/protocols/${protocol.id}/analysis`);
              }}
            >
              Priprema za stampu
            </Button>
          )}
          <AddAnalysisButton userId={userId!} protocolId={protocol.id} />
        </Stack>
        <UserAnalysis
          userId={userId}
          protocolId={protocol.id}
          analysis={analysis}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default UserProtocols;
