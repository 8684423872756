import {
  Box,
  Drawer,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import useAnalysis from "../../api/hooks/useAnalysis";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteAnalysis,
  editAnalysis as editAnalysisApi,
} from "../../api/analysisAPI";
import { useNavigate } from "react-router-dom";
import AnalysisEntity, {
  Analysis,
  CovidAnalysisEntity,
  SwabAnalysisEntity,
} from "../../types/Analysis";
import SwabForm from "../forms/SwabForm";
import CovidForm from "../forms/CovidForm";
import { flow, set } from "lodash/fp";

interface Props {
  userId: string;
  protocolId: string;
  analysis: SwabAnalysisEntity[];
}

const UserAnalysis = ({ userId, protocolId, analysis }: Props) => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [editAnalysis, setEditAnalysis] = useState<
    CovidAnalysisEntity | SwabAnalysisEntity
  >();
  const onEditAnalysis = (data: SwabAnalysisEntity) => {
    console.log("Edit", data);
    editAnalysisApi(userId, protocolId, editAnalysis?.id || "", data).then(
      console.log,
    );
    setOpen(false);
    setEditAnalysis(undefined);
  };
  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "grey" }}>
            <TableRow>
              <TableCell>Tip</TableCell>
              <TableCell align="right">Rezultat</TableCell>
              <TableCell align="right">Datum testiranja</TableCell>
              <TableCell align="right">Cena</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>

          <TableBody>
            {analysis?.map((a) => (
              <TableRow
                hover
                style={{ cursor: "pointer" }}
                key={a.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={(event) => {
                  event.stopPropagation();
                  setEditAnalysis(a);
                  setOpen(true);
                  // navigate(
                  //   `/user/${userId}/protocols/${protocolId}/analysis/${a.id}`,
                  // )
                }}
              >
                <TableCell component="th" scope="row">
                  {a.type}
                </TableCell>
                <TableCell align="right">{a.result}</TableCell>
                <TableCell align="right">
                  {format(a.dateOfIssue, "dd.MM.yyyy HH:mm:ss")}
                </TableCell>
                <TableCell align="right">{a.price}</TableCell>
                <TableCell align="right">
                  {/*<IconButton*/}
                  {/*  color="primary"*/}
                  {/*  component="span"*/}
                  {/*  onClick={(event) => {*/}
                  {/*    event.stopPropagation();*/}
                  {/*    setEditAnalysis(a);*/}
                  {/*    setOpen(true);*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <EditIcon />*/}
                  {/*</IconButton>*/}
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    // @ts-ignore
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteAnalysis(userId, protocolId, a.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Drawer open={isOpen} onClose={() => setOpen(false)} anchor="right">
        <Box
          style={{ height: "100%" }}
          m={10}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {editAnalysis?.generalType === "SWAB" ? (
            <SwabForm
              mode="edit"
              onSubmit={onEditAnalysis}
              userId={userId}
              protocolId={protocolId}
              data={editAnalysis}
            />
          ) : (
            <CovidForm onSubmit={() => setOpen(false)} userId={userId} />
          )}
        </Box>
      </Drawer>
    </Fragment>
  );
};

export default UserAnalysis;
