import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  AnalyseSubTypeValues,
  Analysis,
  AnalysisComments,
  AnalysisCulture,
  AnalysisTypeValues,
  BacteriaType,
  Result,
  SwabAnalysisEntity,
} from "../../types/Analysis";
import { addAnalysis } from "../../api/analysisAPI";
import { flow, set } from "lodash/fp";
import React from "react";
import { get, isArray, isEmpty } from "lodash";
import bacteries from "../../types/Bactery";
import Bactery from "../../types/Bactery";
import BacteriasInputRHF from "./BacteriasInputRHF";
import AntibioticsInputRHF from "./AntibioticsInputRHF";
import RHFSwitch from "../rhf/RHFSwitch";

const StyledTextField = styled(TextField)`
  & .MuiFormHelperText-root {
    color: #d32f2f;
  }
  margin-bottom: 20px;
`;
type SwabFormProps = {
  onSubmit: (data: SwabAnalysisEntity) => void;
  userId: string;
  protocolId: string;
  mode: "edit" | "create";
  data: SwabAnalysisEntity;
};

const prepareSubType = (type: string): [string, string][] => {
  return Object.entries(get(AnalyseSubTypeValues, type, {})) || [];
};
const prepareCulture = (type: string, subtype: string): [string, string][] => {
  return Object.entries(get(AnalysisCulture, `${type}.${subtype}`, {})) || [];
};
const SwabForm = ({ onSubmit, mode }: SwabFormProps) => {
  const onAddAnalysis = (data: SwabAnalysisEntity) => {
    console.log("Create 1", data);
    const tempData = flow(
      set("dateOfIssue", new Date()),
      set("generalType", "SWAB"),
      set("doneResult", Boolean(data.doneResult)),
    )(data);
    console.log("create 2", tempData);
    // addAnalysis(userId, protocolId, tempData).then(console.log);
    onSubmit(tempData);
    reset(defaultAnalysis);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useFormContext<SwabAnalysisEntity>();

  const type = useWatch({ control, name: "type" });
  const subType = useWatch({ control, name: "subType" }) || "";
  const culture = useWatch({ control, name: "culture" });
  const result = useWatch({ control, name: "result" });
  const validated = useWatch({ control, name: "doneResult" });
  const handleClick = (text: string) => {
    const currentText = getValues("comment") || ""; // Get current value of the text field
    setValue("comment", currentText + text);
  };
  return (
    <form id="analysis-form" onSubmit={handleSubmit(onAddAnalysis)}>
      <Stack direction="column" spacing={2}>
        {mode === "edit" && (
          <Stack direction="row" spacing={2} justifyContent="space-between">
            {validated ? (
              <Alert severity="success" variant="filled">
                Analiza je validirana
              </Alert>
            ) : (
              <Alert severity="error" variant="filled">
                Analiza nije validirana
              </Alert>
            )}
            <RHFSwitch name="doneResult" label="Validirano" />
          </Stack>
        )}
        <Controller
          rules={{
            required: { value: true, message: "Ovo polje je obavezno." },
          }}
          name="directive"
          control={control}
          render={({ field }) => (
            <StyledTextField
              {...field}
              multiline
              rows={4}
              label="Direktiva"
              error={!!errors.type}
              helperText={errors.type?.message}
              style={{ marginBottom: 15 }}
            />
          )}
        />
        <FormControl fullWidth>
          <InputLabel id="input-result-label">Tip Analize</InputLabel>
          <Controller
            name="type"
            control={control}
            rules={{
              required: { value: true, message: "Ovo polje je obavezno." },
            }}
            render={({ field }) => (
              <Select
                {...field}
                label="Tip analize"
                style={{ marginBottom: 15 }}
              >
                {Object.entries(AnalysisTypeValues).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
        {!isEmpty(get(AnalyseSubTypeValues, type, {})) && (
          <FormControl fullWidth>
            <InputLabel id="input-result-label">Tip Analize</InputLabel>
            <Controller
              name="subType"
              control={control}
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Tip analize"
                  style={{ marginBottom: 15 }}
                >
                  {prepareSubType(type).map(([key, value]) => (
                    <MenuItem value={key} key={key}>
                      {value || ""}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        )}
        {!isEmpty(get(AnalysisCulture, `${type}.${subType}`, {})) && (
          <FormControl fullWidth>
            <InputLabel id="input-result-label">Kultura</InputLabel>
            <Controller
              name="culture"
              control={control}
              rules={{
                required: { value: true, message: "Ovo polje je obavezno." },
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Tip analize"
                  style={{ marginBottom: 15 }}
                >
                  {prepareCulture(type, subType).map(([key, value]) => (
                    <MenuItem value={key} key={key}>
                      {value || ""}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        )}

        <FormControl fullWidth>
          <Controller
            name="result"
            control={control}
            rules={{
              required: { value: true, message: "Ovo polje je obavezno." },
            }}
            render={({ field }) => (
              <ToggleButtonGroup {...field} exclusive>
                <ToggleButton value="POSITIVE" color="error">
                  Pozitivno
                </ToggleButton>
                <ToggleButton value="NEGATIVE" color="success">
                  Negativno
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </FormControl>
        {result === "NEGATIVE" && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1, // Adjust gap between chips as needed
            }}
          >
            {getComment(type, subType, culture).map((i: string) => (
              <Chip
                sx={{
                  width: "fit-content",
                  maxWidth: "100%",
                }}
                label={i}
                onClick={() => handleClick(i)}
              />
            ))}
          </Box>
        )}

        <Controller
          rules={{
            required: { value: true, message: "Ovo polje je obavezno." },
          }}
          name="comment"
          control={control}
          render={({ field }) => (
            <StyledTextField
              {...field}
              multiline
              rows={4}
              label="Komentar"
              error={!!errors.type}
              helperText={errors.type?.message}
              style={{ marginBottom: 15 }}
            />
          )}
        />
        {result === "POSITIVE" && (
          <>
            <BacteriasInputRHF />
            <AntibioticsInputRHF />
          </>
        )}
        <Button color="success" variant="contained" type="submit">
          {mode === "edit" ? "Azuriraj analizu" : "Kreiraj analizu"}
        </Button>
      </Stack>
    </form>
  );
};

const getComment = (type: string, subtype?: string, culture?: string) => {
  const tempArr = [type];
  if (subtype) {
    tempArr.push(subtype);
  }
  if (culture) {
    tempArr.push(culture);
  }
  const tempVal = get(AnalysisComments, tempArr.join("."), []);

  return isArray(tempVal) ? tempVal : [];
};

export const defaultAnalysis = {
  type: "",
  subType: "",
  generalType: "SWAB",
  method: "Lumiquick Diagnostics Inc. Usa - Immunochromatographic",
  result: Result.NEGATIVE,
  dateOfIssue: new Date(),
  dateOfSampling: new Date(),
  referenceValues: "Positive/Negative",
  deleted: false,
} as unknown as SwabAnalysisEntity;

const SwabFormProvider = (props: SwabFormProps) => {
  const methods = useForm<SwabAnalysisEntity>({ defaultValues: props.data });
  return (
    <FormProvider {...methods}>
      <SwabForm {...props} />
    </FormProvider>
  );
};

export default SwabFormProvider;
