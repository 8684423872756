import {
  AnalysisCultureLabels,
  AnalysisLabels,
  AntibioticResultType,
  BacteriaType,
  Result,
  SwabAnalysisEntity,
} from "../../types/Analysis";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { get } from "lodash";

interface SwabAnalysisViewProps {
  analysis: SwabAnalysisEntity;
  protocolId: string;
  userId: string;
}

const SwabAnalysisView = ({
  userId,
  protocolId,
  analysis,
}: SwabAnalysisViewProps) => {
  return (
    <Stack spacing={2} direction="column">
      <Typography
        sx={{ fontWeight: "bold" }}
        gutterBottom
        alignSelf="flex-start"
      >
        {getSwabLabel(analysis.type, analysis.subType)}
        {getCultureLabel(analysis.culture!)}
      </Typography>
      {/*<Typography gutterBottom>*/}
      {/*  Rezultat: {getResultLabel(analysis.result)}*/}
      {/*</Typography>*/}
      <Typography gutterBottom>
        Rezultat: {getBacteriaLabel(analysis.bacterias)}
      </Typography>
      <Stack direction="column" spacing={1} alignItems="flex-start">
        <Typography>Komentar</Typography>
        <Typography>{analysis.comment}</Typography>
      </Stack>
      {/*<Typography>{analysis.bacterias}</Typography>*/}
      <Divider sx={{ borderBottomWidth: 1, marginY: 2 }} />
      {analysis.antibiotics && (
        <AntibioticsListView antibiotics={analysis.antibiotics} />
      )}
    </Stack>
  );
};

const getSwabLabel = (type: string, subtype?: string) => {
  console.log(type, subtype);
  const paths = [type];
  if (subtype) {
    paths.push(subtype);
  }
  return (
    get(AnalysisLabels, paths.join(".")) ||
    `Odrabrati naziv - ${type} - ${subtype}`
  );
};

const getCultureLabel = (culture: string) => {
  const label = get(AnalysisCultureLabels, culture);
  return label ? ` - ${label}` : "";
};

const getBacteriaLabel = (bacterias: BacteriaType[]) => {
  if (bacterias.length === 0) return "";
  const [bacteria] = bacterias;
  return `${bacteria.name} ${bacteria.value}`;
};

const getResultLabel = (result: Result) => {
  switch (result) {
    case Result.NEGATIVE:
      return "Negativan";
    case Result.POSITIVE:
      return "Pozitivan";
  }
};

const AntibioticsListView = ({
  antibiotics,
}: {
  antibiotics: AntibioticResultType[];
}) => {
  return (
    <Stack spacing={1} direction="column">
      {antibiotics.map((a) => (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ borderBottom: "1px dashed #D3D3D3" }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              "@media print": {
                fontSize: "12px",
              },
            }}
          >
            {a.name}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "14px",
              "@media print": {
                fontSize: "12px",
              },
            }}
          >
            {a.value}
          </Typography>
        </Stack>
      ))}
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: "14px",
          "@media print": {
            fontSize: "12px",
          },
        }}
      >
        Napomena
      </Typography>
      <Typography align="left">
        S – Osetljiv pri standardnom režimu doziranja
      </Typography>
      <Typography align="left">
        I – Osetljiv pri povećanoj izloženosti (prilagođavanjem načina primene,
        veličine doze, intervala doziranja, vremena infuzije, načina
        distribucije I izlučivanja leka, koncentrisanjem leka na mestu
        infekcije)
      </Typography>
      <Typography align="left">R – Neosetljiv </Typography>
    </Stack>
  );
};

export default SwabAnalysisView;
