import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import useModal from "../../hooks/useModal";
import { Controller, useForm } from "react-hook-form";
import { Gender, User } from "../../types/User";
import { useUserSearchContext } from "../../providers/UserSearchProvider";
import { isNil, omitBy } from "lodash/fp";
import { DatePicker } from "@mui/x-date-pickers";
import genderLabel from "../../utils/genderLabel";

const UserSearchButton = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const [userSearchState, setUserSearchState] = useUserSearchContext();
  const { handleSubmit, control, reset } = useForm<Partial<User>>({
    defaultValues: userSearchState,
  });

  const onOpenModal = () => {
    reset(userSearchState);
    openModal();
  };

  const onUserSearch = (data: Partial<User>) => {
    setUserSearchState(omitBy(isNil)(data));
    closeModal();
  };
  return (
    <>
      <Button
        startIcon={<SearchIcon />}
        color="primary"
        variant="contained"
        onClick={onOpenModal}
      >
        Pretrazi korisnika
      </Button>
      {isOpen && (
        <Dialog open={isOpen} onClose={closeModal} maxWidth="md" fullWidth>
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <Box
                width={"100%"}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h5" color="inherit">
                  Pretrazi korisnika
                </Typography>
                <IconButton onClick={closeModal} color="inherit">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <form id="user-search-form" onSubmit={handleSubmit(onUserSearch)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name="userId"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Evidencioni broj"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="jmbg"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="JMBG"
                        defaultValue=""
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="firstname"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} label="Ime" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="lastname"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} label="Prezime" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        value={field.value || null}
                        label="Datum rodjenja"
                        format="dd.MM.yyyy"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} label="Email" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} label="Adresa" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="telephoneNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} label="Broj telefona" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="input-result-label">Pol</InputLabel>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} label="Pol">
                          <MenuItem value={undefined}>
                            <em>None</em>
                          </MenuItem>
                          {Object.keys(Gender).map((res) => (
                            <MenuItem value={res} key={res}>
                              {genderLabel(res as Gender)}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit" form="user-search-form">
              Search
            </Button>
            <Button onClick={closeModal}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UserSearchButton;
