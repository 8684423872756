import useFirestoreCollection from "./useFirestoreCollection";
import { useMemo } from "react";
import { orderBy, where } from "firebase/firestore";
import { set } from "lodash/fp";
import AnalysisEntity, {
  normalizeAnalysis,
  SwabAnalysisEntity,
} from "../../types/Analysis";

export default function useAnalysis(userId: string, protocolId: string) {
  const analysisDocs = useFirestoreCollection(
    `users/${userId}/protocols/${protocolId}/analysis`,
    useMemo(
      () => [where("deleted", "!=", true), orderBy("createdAt", "desc")],
      [],
    ),
  );
  return analysisDocs?.docs.map(
    (u) => normalizeAnalysis(set("id", u.id)(u.data())) as SwabAnalysisEntity,
  ) as SwabAnalysisEntity[];
}
